import { MapContainer, ScaleControl } from 'react-leaflet';
// import { useEffect } from 'react';
import Cats from './Cats';
// import { useStore } from '../../hooks';

const mapHeight = { height: '100%' };

export default function Map() {
  // const setUrls = useStore((state) => state.setUrls);

  // useEffect(() => {
  //   let i = 0;
  //   while (i < 500) {
  //     fetch('https://api.thecatapi.com/v1/images/search?limit=10')
  //       .then((res) => res.json())
  //       .then((data) => {
  //         data.forEach((cat: any) => {
  //           setUrls(cat.url);
  //         });
  //       });
  //     i += 1;
  //   }
  // }, []);

  return (
    <div style={{ width: '100%', height: '100%', position: 'fixed' }}>
      <MapContainer center={[0, 0]} zoom={3} style={mapHeight}>
        <ScaleControl />
        <Cats />
      </MapContainer>
    </div>
  );
}
