import { createGlobalStyle } from 'styled-components';

import { useEffect, useState } from 'react';
import { Map } from './components';

export default function App() {
  // create a useeffect that waits 3 seconds on page load and set a state to true
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setWaiting(false);
    }, 3000);
  }, []);
  return (
    <>
      <GlobalStyle />
      {waiting === false ? <Map /> : <LoadingComponent />}
    </>
  );
}

function LoadingComponent() {
  // make a merry christmas cat joke/pun
  return (
    <div
      style={{
        margin: 'auto',
        width: '100%',
        textAlign: 'center',
        marginTop: '50vh'
      }}>
      Merry Christmas My LUVVVVVVV
      <br />
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
